.item.w-order {
  padding-bottom: 1.25rem;
}

.menu-page > div:not(.contact-main) {
  z-index: 999;
}

.menu-page > div {
  background-color: inherit;
}

.sections.template-2 {
  display: flex;
  min-height: calc(100vh - 64px - 4.5rem - 9rem);
}

.sections.template-2 > div {
  width: 0%;
  height: 0;
}

.sections.template-2 > div.selected {
  width: 100%;
  height: auto;
  position: relative;
}

.sections.template-2 > div.selected h2 {
  display: none;
}

.selector > div::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

.selector > div::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.5);
}

.selector > div::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(136, 136, 136, 0.6);
}

.selector > div::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.6);
}

.selector {
  position: sticky;
  display: flex;
  top: 56px;
  margin-bottom: 1rem;
  background-color: var(--app-bg, #fff);
  z-index: 2;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  box-shadow: 0px 4px 10px -11px;
  z-index: 1101;
  height: 4rem;
}

.selector > div {
  overflow: auto;
  display: flex;
  font-weight: 600;
  padding: 0.75rem 1rem;
  margin: auto;
}

.selector > div > div {
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
  border: 1px solid;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  border-radius: 4px;
}

.selector div.selected {
  background-color: var(--app-section-color, #fff);
}

.selector div.selected > span {
  color: var(--app-bg, #121212);
}

.customization {
  margin-left: 1rem;
}

.customization > span {
  display: inline-block;
}

.customization > span:not(:last-child) {
  margin-right: 0.5rem;
}

.customization::before {
  content: "○";
  position: absolute;
  left: 0.5rem;
}

@media (min-width: 600px) {
  .selector {
    top: 64px;
  }

  .anchor {
    top: calc(-7.5rem - 64px);
  }
}
