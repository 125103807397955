html,
body {
  margin: 0;
  font-size: var(--app-font-size, 15px);
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  padding-right: 0 !important; /* Bootstrap modal keeps adding it */
  overscroll-behavior: contain;
  overflow: unset !important;
  display: flex;
  flex-direction: row-reverse; /* Because on laptop we show the drawer and he is put at the end of body */
}

#root {
  height: 100%;
  width: 100%;
  /*overflow: scroll;*/
  background-color: white;
  visibility: hidden;
  overflow: auto;
}

.clickable {
  cursor: pointer;
}

/* TODO move it somewhere else */
.title {
  display: flex;
  width: 100%;
  overflow: auto;
  position: relative;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.title::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.title {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.carrousel-content {
  display: inline-flex;
  margin: auto;
  padding: 0;
  overflow: auto;
  align-items: center;
  max-width: 100%;
}

.lazy-image-container {
  align-items: center;
  display: flex;
}

.lazy-image {
  max-height: 14rem;
  max-width: calc(100vw - 2rem);
  display: inline-flex;
  border-radius: 6px;
  border-radius: var(--tas-rad, 6px);
  cursor: pointer;
}

.lazy-image.small {
  height: 10rem;
  margin-bottom: 1.5rem;
}

.lazy-image-placeholder {
  max-width: 100%;
  max-height: 16rem;
  min-width: 20rem;
  display: flex;
  min-height: 10rem;
}

.lazy-image-placeholder div {
  margin: auto;
}

.fullscreen {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
  z-index: 1201;
}

.fullscreen img {
  max-height: 100vh;
  max-height: calc(100vh - 4rem);
  height: auto;
}

section {
  max-width: 800px; /* use px because of rule to know if we are on laptop or not */
  margin: auto;
  background-color: transparent;
  color: var(--app-section-color, #121212);
}

.sections {
  list-style: none;
  padding: 0 0.5rem;
  display: flow-root;
}

/* TODO(gregoire) move it somewhere else */
.subsections {
  list-style: none;
  margin: 0;
  padding: 0;
}

.section-description {
  margin-bottom: 1rem;
}

.bottom-elements {
  margin-top: auto;
  padding: 1rem;
}

.bottom-elements .modal-button {
  min-width: calc(100% - 1.5rem);
}

.items {
  list-style: none;
  margin: 0;
  padding: 0;
  /*font-size: large;*/
}

.item {
  margin-bottom: 0.8rem;
  list-style: none;
  position: relative;
  margin: 2px;
  border-radius: var(
    --tas-rad,
    6px
  ) !important; /* TODO: remove from Theme.css*/
  overflow: hidden;
  padding: 0.5rem;
  color: var(--item-color, #121212);
  background-color: var(--item-bg, #fff);
}

.item .price {
  right: 1rem;
  max-width: 50%;
  width: fit-content;
  white-space: pre-line;
}

.item .price ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.item .price li {
  list-style: none;
  margin: auto 0;
  padding: 0;
  text-align: right;
}

.item .price .selected-price {
  font-weight: bold;
}

.item .label {
  display: inline-block;
  font-weight: 400;
  margin-right: 0.5rem;
  filter: opacity(0.65);
}

.item .icons {
  margin-top: 0.5rem;
}

.item .icon {
  display: inline-flex;
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
}

.filter .icon,
.item .icon {
  width: auto;
  height: 1.5rem;
}

.footnote,
.section-description {
  display: flex;
  padding: 0 0.3rem;
  font-weight: var(--section-desc-weight, bold);
}

.footnote {
  margin-bottom: 1.4rem;
}

.footnote > div:first-child,
.section-description > div:first-child {
  margin-right: 0.3rem;
}

.item table {
  width: -webkit-fill-available;
  width: -moz-available;
}

header {
  box-shadow: unset !important;
}

.anchor {
  display: block;
  position: absolute;
  top: calc(-6.5rem - 56px);
  visibility: hidden;
  width: 1px;
  height: 1px;
}

.sanchor {
  display: block;
  position: absolute;
  top: -56px;
  visibility: hidden;
  width: 1px;
  height: 1px;
}

.contact-main,
.watermark {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin-top: auto;
}

.watermark {
  font-size: 90%;
  z-index: 1;
  background: inherit;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid lightgrey;
  display: flex;
}

.MuiToolbar-root {
  cursor: pointer;
}

.contact-drawer {
  margin-bottom: 3rem;
}

.modal-view {
  display: flex;
}

.modal-view::before {
  content: "";
  position: absolute;
  inset: 0;
  backdrop-filter: blur(5px);
  z-index: -1;
}

input[name="message"] {
  background: red;
}

.notes {
  margin-top: 0.5rem;
  font-style: italic;
}

#main-drawer {
  background-color: unset !important;
}

#main-drawer > .MuiPaper-root {
  max-width: 70%;
  min-width: 15rem;
  height: 100%;
  display: flex;
}

.menu-page {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}

.section-collapser {
  position: absolute;
  right: -2rem;
  top: 2.2rem;
  align-items: center;
  display: none;
  display: var(--display-collapser);
}

.subsections .section-collapser {
  display: none;
  display: var(--display-collapser);
  position: absolute;
  right: -2rem;
  top: 1.25rem;
  align-items: center;
}

.menu-page .sections h2,
.menu-page .sections h3 {
  text-decoration: var(--h-decoration, underline wavy);
  text-underline-offset: 4px;
}

.order-button {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
}

.order-button > span {
  position: relative;
  display: flex;
  color: var(--app-bg, #121212);
  margin-bottom: 0.05rem;
}

.order-button::after {
  content: "";
  z-index: -1;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  filter: opacity(0.9);
  border-width: 0 0 2.5rem 2.5rem;
  border-style: solid;
  border-color: transparent transparent var(--app-section-color, #fff)
    transparent;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#root::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#root {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.options {
  list-style: none;
  margin: 0;
  padding-left: 0.4rem;
  padding-right: 0.5rem;
}

.selected-option {
  font-weight: bold;
}

.option-description {
  text-align: right;
  padding-right: 2rem;
}
.option-price {
  text-align: right;
}

/* Default theme definitions. These can be overriden by a restaurant-specific CSS. */
.MuiAppBar-root.MuiAppBar-colorPrimary {
  background-color: var(--app-bar-bg, #fff);
  color: var(--app-bar-color, #fff);
}

.menu-page {
  background-color: var(--app-bg, #fff);
  color: black;
}

.menu-logo {
  max-width: 18rem;
  width: 50%;
  margin: 2rem auto;
  height: auto;
}

a {
  color: #636362;
  text-decoration: none;
  text-transform: none;
}

.drawer-sections {
  text-align: center;
  padding: 0 1rem;
  margin-top: 0;
  overflow: auto;
}

h3 {
  font-weight: normal;
}

.drawer-sections h3 {
  margin: 2rem 0 0 0;
}

h4 {
  font-weight: normal;
  margin: 0.75rem;
}

h2 {
  margin: 2rem 0 1.5rem 0;
}

.is-open .section-collapser {
  transform: rotate(0deg);
  transition: transform 200ms;
}

.is-closed .section-collapser {
  transform: rotate(180deg);
  transition: transform 200ms;
}

[name="custom-svg"] * {
  fill: currentColor !important;
}

.Collapsible__contentInner {
  border: 0;
  margin-bottom: 1px; /* Otherwise, does not unfold the collapsible when set to lazy*/
}

.item-name {
  margin: auto 1rem auto 0;
}

html[dir="rtl"] .item-name {
  margin: auto 0 auto 1rem;
}

html[dir="rtl"] .label {
  margin-right: 0rem;
  margin-left: 0.4rem;
}

html[dir="rtl"] .item .price li {
  text-align: left;
}

/** On laptop we show drawer */
#main-drawer.lg-screen {
  width: 0;
}

#main-drawer.lg-screen .MuiBackdrop-root {
  display: none !important;
}

.lg-screen .MuiAppBar-root {
  padding-left: 300px;
}

.lg-screen section {
  width: calc(100% - 300px);
  margin: auto;
  padding-left: 300px;
}

.lg-screen.contact-main .modal-container,
.lg-screen.watermark {
  margin-left: 300px;
}

.watermark {
  background: white !important;
  width: 100%;
  display: flex;
}

.watermark > a {
  color: black;
  padding: 0.5rem 0;
  margin: auto;
}

.lg-screen.watermark {
  width: calc(100% - 300px);
}

.lg-screen .MuiDrawer-paper {
  width: 300px !important;
  position: inherit;
  max-width: 100% !important;
}

.lg-screen.MuiDrawer-root {
  width: 300px !important;
}

.name {
  /* Useful */
  font-weight: bold;
}

.name,
.item .price {
  vertical-align: top;
  margin-bottom: 0.5rem;
}